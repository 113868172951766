import * as React from "react";
import { Parallax } from "react-parallax";

import image from "../images/world.jpg";
import Layout from "../components/layout";
import Section from "../components/section";
import Typography from "../components/typography";
import { Row, Container, Col } from "react-bootstrap";
import ScheduleForm from "../components/scheduleForm";

const LoanOfficers = () => {
  return (
    <Layout nextPage={{ link: "/banks", text: "Banks & Credit Unions" }}>
      <Section className="partner-page-container" color="light" staticHeight>
        <Parallax
          className="d-flex justify-content-center align-items-center h-100"
          bgImage={image}
          strength={750}
        >
          <Row className="d-flex h-100">
            <Col className= "PartnerCol">
              <Typography type="head" className="nom">
                Connections to lending
                leads through Fundingverse
              </Typography>
              <Typography type="head" className="hide-on-desktop text-center">
                Lending Opportunity Connections
              </Typography>
            </Col>
            <Col>
              <ScheduleForm to="/" />
            </Col>
          </Row>
        </Parallax>
      </Section>
      <Section size="sm" color="light" bg="dark">
        <Container className="text-center py-5">
          <Typography type="title">
            Benefits of A Fundingverse Partnership
          </Typography>
          <ul className="text-start text-light">
            <li className="text-light">
              Find lending opportunities for businesses that are currently need
              funding fast or aren’t eligible for a bank loan
            </li>
            <li className="text-light">
              Be part of creating a more efficient funding cycle and capitalize
              on an efficient way to get high-quality leads
            </li>
            <li className="text-light">
              A new wave of funding customers and leads on our new lending
              market platform.
            </li>
            <li className="text-light">
              Why pay for out-dated lists? Get access to current &amp; real
              opportunities today. Talk to us, schedule a time!
            </li>
          </ul>
        </Container>
      </Section>
    </Layout>
  );
};

export default LoanOfficers;
